var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('auth.Reset Password'))+" 🔒 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('auth.Your new password must be different from previously used passwords'))+" ")]),_c('validation-observer',{ref:"resetForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",attrs:{"id":"reset"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('auth.Phone'),"label-for":"account-phone"}},[_c('b-form-input',{attrs:{"id":"account-phone","readonly":""},model:{value:(_vm.accountData.phone),callback:function ($$v) {_vm.$set(_vm.accountData, "phone", $$v)},expression:"accountData.phone"}}),_c('small',[_vm._v(_vm._s(_vm.$t('auth.We might ask you to enter a verification code that we send to this phone' )))])],1),_c('b-form-group',{attrs:{"label-for":"old-password","label":_vm.$t('auth.Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('auth.Password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"old-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"placeholder":"········"},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"new-password","label":_vm.$t('auth.New Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('auth.New Password'),"rules":"min:8|required|password:@passwordConfirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"new-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"placeholder":"········","readonly":!_vm.currentPassword},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"password-confirmation","label":_vm.$t('auth.Password Confirmation')}},[_c('validation-provider',{attrs:{"name":"passwordConfirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password-confirmation","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"placeholder":"········","readonly":!_vm.newPassword},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.alert)?_c('b-alert',{attrs:{"show":"","variant":_vm.alertVariant}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":_vm.alertVariant==='success'?'CheckCircleIcon':'AlertCircleIcon'}}),_vm._v(" "+_vm._s(_vm.$t(_vm.alert))+" ")],1)]):_vm._e(),(_vm.passChanged)?_c('b-alert',{attrs:{"show":"","variant":"primary"}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t('auth.Please Sign In using a new password')))]),_c('div',{staticClass:"alert-body"},[_c('span',{staticClass:"align-middle"},[_c('feather-icon',{attrs:{"icon":"ClockIcon"}}),_vm._v(" "+_vm._s(_vm.$t('message.you_will_be_redirected_to_the_authorization_page_in'))+" "+_vm._s(_vm._f("secondsToTimer")(_vm.delay))+" ")],1)])]):_vm._e(),(!_vm.verifyingRequired && !_vm.passChanged)?_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[(_vm.submittingFetch)?_c('span',[_c('b-spinner',{staticClass:"mr-25",attrs:{"type":"grow","small":""}}),_c('span',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.$t('Loading...')))])],1):_c('span',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.$t('auth.Set New Password'))+" ")])]):_vm._e()],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }