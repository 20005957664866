<template>
  <b-overlay
    :show="submittingFetch"
    rounded
    opacity="0.6"
    spinner-medium
    spinner-variant="primary"
    spinner-type="grow"
  >
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="text-center pb-1"
      >
        <offer-add-wizard-button />
      </b-col>
      <b-col
        v-if="points.length"
        cols="12"
        md="6"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-warning"
          block
          class="text-uppercase"
          @click="viewOffersList()"
        >
          <feather-icon
            class="mr-50"
            icon="ListIcon"
          />
          <span class="align-middle">{{ $t('My Offers') }}</span>
        </b-button>
      </b-col>
    </b-row>
    <!--      <b-row-->
    <!--        v-if="userCanAddOffer()"-->
    <!--        class="match-height"-->
    <!--      >-->
    <!--        <b-col-->
    <!--          v-for="point in points"-->
    <!--          :key="point.id"-->
    <!--          cols="12"-->
    <!--        >-->
    <!--          <b-card-->
    <!--            border-variant="primary"-->
    <!--            class="shadow-none"-->
    <!--            no-body-->
    <!--          >-->

    <!--            &lt;!&ndash;              <b-row>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <b-col&ndash;&gt;-->
    <!--            &lt;!&ndash;                  xs="6"&ndash;&gt;-->
    <!--            &lt;!&ndash;                  class="text-left"&ndash;&gt;-->
    <!--            &lt;!&ndash;                >&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <b-dropdown&ndash;&gt;-->
    <!--            &lt;!&ndash;                    id="dropdown-grouped"&ndash;&gt;-->
    <!--            &lt;!&ndash;                    variant="link"&ndash;&gt;-->
    <!--            &lt;!&ndash;                    left&ndash;&gt;-->
    <!--            &lt;!&ndash;                    class="dropdown-icon-wrapper"&ndash;&gt;-->
    <!--            &lt;!&ndash;                  >&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <template #button-content>&ndash;&gt;-->

    <!--            &lt;!&ndash;                      <feather-icon&ndash;&gt;-->
    <!--            &lt;!&ndash;                        icon="MoreVerticalIcon"&ndash;&gt;-->
    <!--            &lt;!&ndash;                        size="18"&ndash;&gt;-->
    <!--            &lt;!&ndash;                        class="cursor-pointer"&ndash;&gt;-->
    <!--            &lt;!&ndash;                      />&ndash;&gt;-->
    <!--            &lt;!&ndash;                    </template>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <b-dropdown-item :to="{ name: 'apps-service-offer-info', params: { id: point.id } }">&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <feather-icon icon="FileTextIcon" />&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <span class="align-middle ml-50">{{ $t('View') }}</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    </b-dropdown-item>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <b-dropdown-item&ndash;&gt;-->
    <!--            &lt;!&ndash;                      v-if="ability.can('update', point)"&ndash;&gt;-->
    <!--            &lt;!&ndash;                      :to="{ name: 'apps-service-offer-edit', params: { id: point.id } }"&ndash;&gt;-->
    <!--            &lt;!&ndash;                    >&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <feather-icon icon="EditIcon" />&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <span class="align-middle ml-50">{{ $t('Edit') }}</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    </b-dropdown-item>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <b-dropdown-item v-if="ability.can('update', point)">&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <feather-icon icon="TrashIcon" />&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <span&ndash;&gt;-->
    <!--            &lt;!&ndash;                        class="align-middle ml-50"&ndash;&gt;-->
    <!--            &lt;!&ndash;                        @click.prevent="deleteOffer(point)"&ndash;&gt;-->
    <!--            &lt;!&ndash;                      >{{ $t('Delete') }}</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    </b-dropdown-item>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </b-dropdown>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </b-col>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <b-col xs="6">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <div class="position-absolute statuses">&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <b-badge&ndash;&gt;-->
    <!--            &lt;!&ndash;                      :variant="`light-${getPostStatusProperty(point.post_status, 'class')}`"&ndash;&gt;-->
    <!--            &lt;!&ndash;                    >&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <small>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        {{ $t(point.post_status) }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <feather-icon&ndash;&gt;-->
    <!--            &lt;!&ndash;                          :icon="getPostStatusProperty(point.post_status, 'icon')"&ndash;&gt;-->
    <!--            &lt;!&ndash;                        />&ndash;&gt;-->
    <!--            &lt;!&ndash;                      </small>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    </b-badge>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </b-col>&ndash;&gt;-->

    <!--            &lt;!&ndash;              </b-row>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <b-card-header>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <b-card-title>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span class="text-uppercase">{{ point.name }}</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </b-card-title>&ndash;&gt;-->
    <!--            &lt;!&ndash;              </b-card-header>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <b-card-body>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <b-card-text>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  {{ point.description }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                </b-card-text>&ndash;&gt;-->
    <!--            &lt;!&ndash;              </b-card-body>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <b-card-footer>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <b-badge&ndash;&gt;-->
    <!--            &lt;!&ndash;                  pill&ndash;&gt;-->
    <!--            &lt;!&ndash;                  variant="light-primary"&ndash;&gt;-->
    <!--            &lt;!&ndash;                >&ndash;&gt;-->
    <!--            &lt;!&ndash;                  {{ getTypeTitle(point.point_type.slug) }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                </b-badge>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <b-badge&ndash;&gt;-->
    <!--            &lt;!&ndash;                  v-for="(purpose,index) in point.point_purposes"&ndash;&gt;-->
    <!--            &lt;!&ndash;                  :key="index"&ndash;&gt;-->
    <!--            &lt;!&ndash;                  pill&ndash;&gt;-->
    <!--            &lt;!&ndash;                  variant="light-secondary"&ndash;&gt;-->
    <!--            &lt;!&ndash;                >&ndash;&gt;-->
    <!--            &lt;!&ndash;                  {{ getPurposeTitle(purpose.slug) }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                </b-badge>&ndash;&gt;-->
    <!--            &lt;!&ndash;              </b-card-footer>&ndash;&gt;-->

    <!--            <offer-details :offer="point" />-->
    <!--            <b-card-footer>-->
    <!--              <b-row>-->

    <!--                <b-col>-->
    <!--                  <b-button-->
    <!--                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
    <!--                    variant="primary"-->
    <!--                    block-->
    <!--                    class="mb-1"-->
    <!--                    :to="{ name: 'apps-service-offer-edit', params: { id: point.id } }"-->
    <!--                  >-->
    <!--                    <feather-icon-->
    <!--                      icon="EditIcon"-->
    <!--                      class="mr-50 d-none d-sm-inline"-->
    <!--                    />-->
    <!--                    <span class="align-middle">{{ $t('Edit') }}</span>-->
    <!--                  </b-button>-->
    <!--                </b-col>-->
    <!--                <b-col>-->
    <!--                  <b-button-->
    <!--                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
    <!--                    variant="danger"-->
    <!--                    block-->
    <!--                    class="mb-1"-->
    <!--                    @click.prevent="deleteOffer(point)"-->
    <!--                  >-->
    <!--                    <feather-icon-->
    <!--                      icon="TrashIcon"-->
    <!--                      class="mr-50 d-none d-sm-inline"-->
    <!--                    />-->
    <!--                    <span class="align-middle">{{ $t('Delete') }}</span>-->
    <!--                  </b-button>-->
    <!--                </b-col>-->
    <!--              </b-row>-->
    <!--            </b-card-footer>-->
    <!--          </b-card>-->
    <!--        </b-col>-->
    <!--      </b-row>-->
    <!--  </b-overlay>-->
  </b-overlay></template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardFooter,
  BOverlay,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import store from '@/store'
import { getPostStatusProperty, getUserAvatarText } from '@/mixins/functions'
import { usePointsUi } from '@/views/apps/service/usePoints'
import { truncate } from '@/filters/input'
import { showToast } from '@/mixins/notification/toasts'
import { serviceOptions } from '@/mixins/options'
import { formatDateTime } from '@/filters/dateTime'
import OfferDetails from '@/views/apps/service/offer/details/OfferDetails.vue'
import OfferAddWizardButton from '@/views/apps/service/offer/edit/OfferAddWizardButton.vue'
import router from '@/router'
import { useShopFiltersSortingAndPagination, useServiceRequestsRemoteData } from './usePointList'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BRow,
    BCol,
    BCard,
    BButton,
    BCardFooter,
    BOverlay,
    OfferDetails,
    OfferAddWizardButton,
  },
  filters: {
    truncate, formatDateTime,
  },

  mixins: [getPostStatusProperty, getUserAvatarText, showToast],

  computed: {
    points() {
      return store.getters['point/offers']
    },
  },
  methods: {
    deleteOffer(point) {
      this.$swal({
        title: this.$t('Are you sure?'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('point/deleteServiceOffer', { id: point.id }).then(response => {
            if ([200, 201, 'success'].includes(response.status)) {
              this.points.splice(this.points.findIndex(item => item.id === point.id), 1)
              this.showToast(response, 'Offer')
            }
          })
        }
      })
    },
  },
  setup() {
    const { getPurposeTitle, getTypeTitle } = serviceOptions
    const {
      sortBy, filters,
    } = useShopFiltersSortingAndPagination()

    const {
      handleEditOfferActionClick, resolvePointTypeBadgeVariant,
    } = usePointsUi()

    const ability = defineAbilityForCurrentUser()

    const {
      fetchPoints, submittingFetch,
    } = useServiceRequestsRemoteData()

    const fetchFilteredPoints = () => {
      const args = {
        sort_by: sortBy.value.value,
        order: filters.value.order,
      }
      fetchPoints(args)
    }
    fetchFilteredPoints()

    function viewOffersList() {
      router.push({ name: 'apps-service-my-offers' })
    }
    return {
      // useShopUi
      handleEditOfferActionClick,
      resolvePointTypeBadgeVariant,

      // useServiceRequestsRemoteData
      submittingFetch,
      ability,
      getPurposeTitle,
      getTypeTitle,
      viewOffersList,
    }
  },
}
</script>
<style lang="scss" >

.statuses{
  top: -1px!important;
  right:0;

}
.statuses>span{
  border-radius: 0;
}
.statuses>span:first-child:last-child {
  border-radius:  0 0.428rem 0 0.428rem;
}
.statuses>span:first-child {
  border-radius:  0 0 0 0.428rem;
}
.statuses>span:last-child {
  border-radius:  0 0.428rem 0 0 ;
}

</style>
