<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="accountData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t("Error fetching user data") }}
      </h4>
      <div class="alert-body">
        {{ $t("No user found with this id") }}
      </div>
    </b-alert>
    <b-overlay
      :show="submittingFetch"
      rounded
      opacity="0.6"
      spinner-medium
      spinner-variant="primary"
      spinner-type="grow"
    >
      <b-row>
        <b-col>

          <validation-observer
            ref="accountSettings"
            #default="{ invalid }"
          >

            <b-tabs
              v-if="accountData"
              v-model="tabIndex"
            >
              <b-tab
                v-for="tab in tabs"
                :key="tab.dataField"
                :href="`#${tab.dataField}`"
                :value="tabIndex"
              >
                <template #title>
                  <feather-icon
                    :icon="tab.icon"
                    size="18"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{ $t(tab.label) }}</span>
                </template>
                <b-card>
                  <component
                    :is="tab.component"
                    :account-data="accountData"
                    class="mt-2 pt-75 pl-1 pr-1"
                    @sections="setDefaultSections"
                  />
                </b-card>
              </b-tab>
            </b-tabs>
            <b-button
              v-if="accountDataUpdated()"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="invalid"
              @click="submit"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span>{{ $t("Save") }}</span>
            </b-button>

            <!--            <div>-->
            <!--              <b-navbar-->
            <!--                v-if="accountDataUpdated() && isMobile()"-->
            <!--                :style="{-->
            <!--                }"-->
            <!--                :toggleable="false"-->
            <!--                class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"-->
            <!--                :class="{'fixed-bottom': $store.getters['app/currentBreakPoint'] !== 'xl'}"-->
            <!--              >-->
            <!--                <b-button-->
            <!--                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
            <!--                  variant="primary"-->
            <!--                  :disabled="invalid"-->
            <!--                  block-->
            <!--                  @click="submit"-->
            <!--                >-->
            <!--                  <feather-icon-->
            <!--                    icon="SaveIcon"-->
            <!--                    class="mr-50"-->
            <!--                  />-->
            <!--                  <span>{{ $t('Save') }}</span>-->
            <!--                </b-button>-->
            <!--              </b-navbar>-->
            <!--            </div>-->
          </validation-observer>

        </b-col>
        <!--      <b-col-->
        <!--        lg="4"-->
        <!--      >-->
        <!--        <account-setting-user-profile :user="accountData" />-->
        <!--      </b-col>-->
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BCardHeader,
} from 'bootstrap-vue'

import store from '@/store'
import { customToast } from '@/mixins/notification/toasts'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter'
import AccountSettingTabGeneral from './AccountSettingTabGeneral.vue'
import AccountSettingTabPassword from './AccountSettingTabPassword.vue'
import AccountSettingTabInformation from './AccountSettingTabInformation.vue'
import AccountSettingTabSocial from './AccountSettingTabSocial.vue'
import AccountSettingTabSettings from './AccountSettingTabSettings.vue'
import AccountSettingTabServices from './AccountSettingTabServices.vue'
import AccountSettingTabNotifications from './AccountSettingTabNotifications.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCardHeader,

    ValidationObserver,

    AccountSettingTabGeneral,
    AccountSettingTabPassword,
    AccountSettingTabInformation,
    AccountSettingTabSocial,
    AccountSettingTabServices,
    AccountSettingTabNotifications,
    AccountSettingTabSettings,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabIndex: '',
    }
  },
  watch: {
    '$route.query.tab': {
      handler(search) {
        this.tabIndex = this.tabs.findIndex(tab => tab.dataField === search)
      },
      immediate: true,
    },
    'accountData.services': {
      handler(newValue, oldValue) {
        if (this.accountDataUpdated()) {
          this.submit()
        }
      },
      immediate: true,
    },

  },

  // mounted() {
  //   this.$watch(() => this.accountData, () => {
  //     store.commit('account/setAccountSettings', this.accountData)
  //   }, { deep: true, immediate: true })
  // },

  beforeRouteLeave(to, from, next) {
    if (this.accountDataUpdated()) {
      this.showAlert(next)
    } else {
      next()
    }
  },

  created() {
    const settings = store.getters['account/accountSettings']
    if (settings) {
      this.accountData = { ...this.accountData, ...settings.data }
    }
    this.fetchAccountSettings()
  },
  methods: {
    accountDataUpdated() {
      return this.savedAccountData.length
        ? this.savedAccountData !== JSON.stringify(this.accountData)
        : false
    },
    setDefaultSections(obj) {
      const tab = Object.keys(obj)[0]
      Object.values(obj)[0].forEach(section => {
        if (!this.accountData[tab][section]) {
          this.accountData[tab][section] = {}
        }
      })
    },
    showAlert(next) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t("You didn't save the Account Settings!"),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          next()
        }
      })
    },
    submit() {
      this.$refs.accountSettings.validate().then(success => {
        if (success) {
          this.save()
        }
      })
    },
  },
  setup() {
    const submittingFetch = ref(false)
    const accountData = ref({
      name: '',
      email: '',
      login: '',
      phone: '',
      public_figure: null,
      bio: '',
      residence_country: '',
      residence_city: '',
      twitter: null,
      facebook: null,
      linkedin: null,
      instagram: null,
      services: [],
      languages: [],
      notification_types: [],
      currency: '',
      lang: '',
      phones: [],
    })

    const savedAccountData = ref({})
    const tabs = [
      {
        icon: 'UserIcon',
        dataField: 'general',
        component: 'AccountSettingTabGeneral',
        label: 'user.profile',
      },
      // {
      //   icon: 'KeyIcon',
      //   dataField: 'password',
      //   component: 'AccountSettingTabPassword',
      //   label: 'Change Password',
      // },
      // {
      //   icon: 'InfoIcon',
      //   dataField: 'info',
      //   component: 'AccountSettingTabInformation',
      //   label: 'Information',
      // },
      // {
      //   icon: 'Share2Icon',
      //   dataField: 'social',
      //   component: 'AccountSettingTabSocial',
      //   label: 'Social',
      // },
      {
        icon: 'BriefcaseIcon',
        dataField: 'services',
        component: 'AccountSettingTabServices',
        label: 'Services',
      },
      {
        icon: 'BellIcon',
        dataField: 'notifications',
        component: 'AccountSettingTabNotifications',
        label: 'Notifications',
      },
      {
        icon: 'ServerIcon',
        dataField: 'settings',
        component: 'AccountSettingTabSettings',
        label: 'Account',
      },
    ]
    const fetchAccountSettings = () => {
      submittingFetch.value = true
      store
        .dispatch('account/fetchAccountSettings')
        .then(response => {
          accountData.value = { ...accountData.value, ...response.data.data }
          savedAccountData.value = JSON.stringify(accountData.value)
        })
        .catch(error => {
          if (error.response.status === 404) {
            accountData.value = undefined
          }
        })
        .finally(() => {
          submittingFetch.value = false
        })
    }
    const save = () => {
      submittingFetch.value = true
      store
        .dispatch('account/updateAccountSettings', accountData.value)
        .then(response => {
          GlobalEventEmitter.$emit('showCustomToast', response, 'Account Settings')
          const successStatuses = [200, 201]
          if (successStatuses.includes(response.status)) {
            savedAccountData.value = JSON.stringify(accountData.value)
            store.dispatch('account/fetchUserCurrent')
          }
        })
        .catch(error => {
          const { errors } = error.response.data
          Object.entries(errors).forEach(([key, value]) => {
            GlobalEventEmitter.$emit('showCustomToast', { status: 'error', statusText: value }, key, null, null, 4000)
          })
        })
        .finally(() => {
          submittingFetch.value = false
        })
    }
    return {
      tabs,
      submittingFetch,
      fetchAccountSettings,
      accountData,
      savedAccountData,
      save,
    }
  },
}
</script>
<style scoped>
.header-navbar {
  min-height: 0;
  padding: 0.5rem 1rem;
}

.dark-layout .header-navbar {
  background-color: rgba(255, 255, 255, 0.5);
}

.header-navbar {
  background-color: rgba(40, 48, 70, 0.5);
}
</style>
