<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <b-form-group
          v-if="user"
          :label="$t('subscription.active_subscriptions')"
          label-for="active_subscriptions"
        >
          <subscription-list
            v-if="user.subscriptions && user.subscriptions.length"
            :subscriptions="user.subscriptions"
            :horizontal="true"
          />
          <subscription-alert
            :header-text="(!user.subscriptions || !user.subscriptions.length)?$tc('subscription.subscriptions_found',0):''"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col cols="12">
        <b-form-group
          :label="$t('Account management')"
          label-for="account_management"
        /></b-col>

    </b-row>
    <b-row>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          block
          @click="deleteAccount"
        >
          <feather-icon
            icon="Trash2Icon"
            class="mr-50"
          />
          <span>{{ $t("auth.delete_account") }} </span>
        </b-button>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <change-password-button
          :account-data="accountData"
        />
      </b-col>

    </b-row>

  </div>

</template>

<script>
import {
  BRow, BCol, BFormGroup, BButton, BContainer,
} from 'bootstrap-vue'
import { showToast } from '@/mixins/notification/toasts'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUserUi } from '@/views/apps/user/useUser'
import { computed, ref } from '@vue/composition-api'
import SubscriptionList from '@/views/apps/subscription/subscriptionList.vue'
import SubscriptionAlert from '@/views/apps/subscription/subscriptionAlert.vue'
import ChangePasswordButton from '@/views/pages/account-setting/components/ChangePasswordButton.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BContainer,
    SubscriptionList,
    SubscriptionAlert,
    ChangePasswordButton,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    displayDeletionStatus() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: this.$t('auth.the_account_has_been_deleted'),
          icon: 'BellIcon',
          variant: 'success',
          text: this.connectionStatus,
        },
      })
    },
    deleteAccount() {
      this.$swal({
        title: this.$t('Are you sure?'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('account/deleteAccountSettings')
            .then(response => {
              this.displayDeletionStatus()
              this.logout()
            })
        }
      })
    },
  },
  setup() {
    const { logout } = useUserUi()
    const displayModalChangePass = ref(false)
    const user = computed(() => store.state.account.user)
    return {
      logout,
      user,
      displayModalChangePass,
    }
  },
}
</script>

<style>

</style>
