<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="warning"
      block
      @click="displayModalChangePass=true"
    >
      <feather-icon
        icon="KeyIcon"
        class="mr-50"
      />
      <span>{{ $t("auth.Reset Password") }} </span>
    </b-button>

    <b-modal
      v-if="displayModalChangePass"
      id="modal-change-pass"
      ref="modal-change-pass"
      v-model="displayModalChangePass"
      centered
      hide-footer
      :static="true"
      :no-close-on-backdrop="true"
    >
      <!-- Modal Form Header -->
      <template #modal-header>
        <b-button
          class="close btn-icon"
          variant="outline-secondary"
          :disabled="preventCloseModal"
          size="sm"
          @click="displayModalChangePass=false"
        >
          <feather-icon
            icon="XIcon"
            size="26"
          />
        </b-button>
      </template>
      <!-- Modal Form Body -->
      <!--Form-->
      <change-password-form
        :account-data="accountData"
        @pass-changed="(val)=>handlePassChanged(val)"
      />
    </b-modal>
  </div>

</template>

<script>
import {
  BButton, BContainer,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import ChangePasswordForm from '@/views/pages/authentication/components/ChangePasswordForm.vue'

export default {
  components: {
    BButton,
    BContainer,

    ChangePasswordForm,
  },
  directives: {
    Ripple,
  },

  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },
  methods: {
  },
  setup() {
    const preventCloseModal = ref(false)
    const displayModalChangePass = ref(false)
    function handlePassChanged(val) {
      preventCloseModal.value = val
    }
    return {
      preventCloseModal,
      displayModalChangePass,
      handlePassChanged,
    }
  },
}
</script>

<style>

</style>
