<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Change Password -->
      <b-card class="mb-0">

        <b-card-title class="mb-1">
          {{ $t('auth.Reset Password') }} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('auth.Your new password must be different from previously used passwords') }}
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="resetForm"
          #default="{invalid}"
        >
          <b-form
            id="reset"
            class="auth-login-form mt-2"
            @submit.prevent="submit"
          >
            <!--phone-->
            <b-form-group
              :label="$t('auth.Phone')"
              label-for="account-phone"
            >
              <b-form-input
                id="account-phone"
                v-model="accountData.phone"
                readonly=""
              />
              <small>{{ $t('auth.We might ask you to enter a verification code that we send to this phone' ) }}</small>
            </b-form-group>

            <!-- old password -->
            <b-form-group
              label-for="old-password"
              :label="$t('auth.Password')"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('auth.Password')"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="old-password"
                    v-model="currentPassword"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    placeholder="········"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- new password -->
            <b-form-group
              label-for="new-password"
              :label="$t('auth.New Password')"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('auth.New Password')"
                rules="min:8|required|password:@passwordConfirmation"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="new-password"
                    v-model="newPassword"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    placeholder="········"
                    :readonly="!currentPassword"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- password confirmation -->
            <b-form-group
              label-for="password-confirmation"
              :label="$t('auth.Password Confirmation')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="passwordConfirmation"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password-confirmation"
                    v-model="passwordConfirmation"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    placeholder="········"
                    :readonly="!newPassword"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-alert
              v-if="alert"
              show
              :variant="alertVariant"
            >
              <div class="alert-body">
                <feather-icon
                  :icon="alertVariant==='success'?'CheckCircleIcon':'AlertCircleIcon'"
                  class="mr-50"
                />
                {{ $t(alert) }}
              </div>
            </b-alert>
            <b-alert
              v-if="passChanged"
              show
              variant="primary"
            >
              <h4 class="alert-heading">
                {{ $t('auth.Please Sign In using a new password') }}</h4>
              <div class="alert-body">

                <span class="align-middle">
                  <feather-icon
                    icon="ClockIcon"
                  />
                  {{ $t('message.you_will_be_redirected_to_the_authorization_page_in') }} {{ delay|secondsToTimer() }}
                </span>
              </div>
            </b-alert>
            <!-- submit buttons -->
            <b-button
              v-if="!verifyingRequired && !passChanged"
              type="submit"
              variant="primary"
              block
              :disabled="invalid"
            >
              <span v-if="submittingFetch">
                <b-spinner
                  type="grow"
                  small
                  class="mr-25"
                />
                <span class="align-middle"> {{ $t('Loading...') }}</span>
              </span>

              <span
                v-else
                class="align-middle"
              >
                {{ $t('auth.Set New Password') }}
              </span>
            </b-button>
          </b-form>
        </validation-observer>

      </b-card>
      <!-- /Change Password -->
    </div>
  </div>

</template>

<script>
import {
  ValidationProvider, ValidationObserver, extend,
} from 'vee-validate'

import {
  digits, min, required,
} from '@validations'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { showToast } from '@/mixins/notification/toasts'
import i18n from '@/libs/i18n'
import {
  BLink, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BCardTitle, BCardText, BSpinner, BCard, BAlert,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { secondsToTimer } from '@/filters/dateTime'
import { useUserUi } from '@/views/apps/user/useUser'

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return target ? value === target : true
  },
  message: i18n.t('auth.password_confirmation_does_not_match'),
})

export default {
  components: {

    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BCard,
    BAlert,
    // validations
    ValidationProvider,
    ValidationObserver,

  },
  filters: {
    secondsToTimer,
  },
  mixins: [togglePasswordVisibility, showToast],
  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      verifyingRequired: false,

      // validation
      required,
      min,
      digits,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },
  methods: {
    submit() {
      this.$refs.resetForm.validate().then(success => {
        if (success) {
          this.resetPassword()
        }
      })
    },

  },
  setup(props, { emit }) {
    const delay = ref(0)
    const passChanged = ref(false)
    const currentPassword = ref('')
    const newPassword = ref('')
    const passwordConfirmation = ref('')
    const submittingFetch = ref(false)
    const alert = ref('')
    const alertVariant = ref('danger')

    const { logout } = useUserUi()

    const logoutWithTimer = sec => {
      delay.value = sec
      if (delay.value > 0) {
        const timer = setInterval(() => {
          delay.value -= 1
          if (delay.value <= 0) {
            clearInterval(timer)
            logout()
          }
        }, 1000)
      }
    }

    const resetPassword = () => {
      submittingFetch.value = true
      const data = {
        current_password: currentPassword.value,
        new_password: newPassword.value,
        new_password_confirmation: passwordConfirmation.value,
      }
      store
        .dispatch('account/sendPasswordChangeRequest', data)
        .then(response => {
          const successStatuses = [200, 201]
          if (successStatuses.includes(response.status)) {
            passChanged.value = true
            alertVariant.value = 'success'
            alert.value = ('auth.Password updated')
            logoutWithTimer(5)
            emit('pass-changed', passChanged.value)
          }
        })
        .catch(error => {
          const { data } = error.response
          alert.value = ('auth.'.concat(data.error))
        })
        .finally(() => {
          submittingFetch.value = false
        })
    }

    return {
      alert,
      alertVariant,
      submittingFetch,
      resetPassword,
      currentPassword,
      newPassword,
      passwordConfirmation,
      delay,
      passChanged,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
